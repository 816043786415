export default [
    {
        id: '1',
        name: '3 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.50%',
        otc: '',
        expired: '31/7/2020',
        date: '',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/campaigns/digital-day-2020/tnc/hlb-digital-day-efd-efd-i-tnc.pdf?icp=hlb-en-promo-midbody-img-dd20'
    },
    {
        id: '2',
        name: '3 / 6 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.60% / 2.70%',
        otc: '',
        expired: '7/7/2020',
        date: '',
        link: 'https://www.affinbank.com.my/AFFINBANK/media/Documents/01072020/TC-eFD-Campaign-Eng-30062020.pdf'
    },
    {
        id: '3',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '2.65%',
        otc: '',
        expired: '8/7/2020',
        date: '',
        link: 'https://www.ambank.com.my/ambank/SiteAssets/SitePages/simple/terms-and-conditions/AmOnlineeFDPromoTnC.pdf'
    },
    {
        id: '4',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.45% / 2.50% / 2.55%',
        otc: '',
        expired: '7/7/2020',
        date: '',
        link: 'https://www.cimbclicks.com.my/pdf/t&c-efd-campaign-may20-en.pdf'
    },
    {
        id: '5',
        name: '7 / 11 MONTHS FIXED DEPOSIT',
        bank: 'Bank Rakyat',
        code: 'br',
        efd: '',
        otc: '2.80% / 3.00%',
        expired: '14/7/2020',
        date: '',
        link: 'http://www.bankrakyat.com.my/d/kempen/campaigns/term_deposit_i_account-544/dwterms/FINAL_ENG_Terma%20dan%20Syarat%20Kempen%20Deposit%20i%20Berjangka.pdf'
    },
    {
        id: '6',
        name: '10 MONTHS eFIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '2.60%',
        otc: '',
        expired: '7/7/2020',
        date: '',
        link: 'https://www.ocbc.com.my/personal-banking/accounts/assets/pdf/OCBC_Online_FD_Promo_TC_22June.pdf'
    },
    {
        id: '7',
        name: '6 / 10 MONTHS FIXED DEPOSIT',
        bank: 'ICBC Bank',
        code: 'icbc',
        efd: '',
        otc: '2.60% / 2.80%',
        expired: '14/7/2020',
        date: '',
        link: 'https://v.icbc.com.cn/userfiles/Resources/ICBC/haiwai/Malaysia/download/2020/MYRFixedDepositTC.pdf'
    },
    {
        id: '8',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.10% / 2.20% / 2.25%',
        otc: '',
        expired: '10/9/2020',
        date: '',
        link: 'https://www.cimbclicks.com.my/pdf/tc-efd-2020-eng-jul.pdf'
    },
    {
        id: '9',
        name: '3 / 6 MONTHS OTC & eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.35% / 2.45%',
        otc: '2.35% / 2.45%',
        expired: '4/8/2020',
        date: '',
        link: ''
    },
    {
        id: '10',
        name: '3 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.05%',
        expired: '31/7/2020',
        date: '',
        link: 'https://www.pbebank.com/pdf/Banking/3m-fdtdi-tc-en.aspx'
    },
    {
        id: '11',
        name: '3 MONTHS PREMIER FD + BOOSTER',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.43%',
        expired: '31/12/2020',
        date: '',
        link: 'https://www.ocbc.com.my/personal-banking/premier/forms/promotion/index.html'
    },
    {
        id: '12',
        name: '3 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.30%',
        expired: '31/7/2020',
        date: '',
        link: 'https://www.ocbc.com.my/personal-banking/accounts/assets/pdf/OCBC%20Online%20FD%20Promo%20FPX_TC_13uly.pdf'
    },
    {
        id: '13',
        name: '3 / 5 / 6 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.48% / 2.60% / 2.55%',
        expired: '28/7/2020',
        date: '',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/TnC_eFDCampaign_Eng.pdf'
    },
    {
        id: '14',
        name: '9 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.50%',
        expired: '31/8/2020',
        date: '',
        link: ''
    },
    {
        id: '15',
        name: '7 / 11 MONTHS FIXED DEPOSIT',
        bank: 'Bank Rakyat',
        code: 'br',
        efd: '',
        otc: '2.55% / 2.75%',
        expired: '31/8/2020',
        date: '',
        link: 'https://www.bankrakyat.com.my/d/campaigns/campaigns/term_deposit_i_account-544/dwterms/Terms%20Conditions_Effective%2015%20July%202020.pdf'
    },
    {
        id: '16',
        name: '6 MONTHS FIXED DEPOSIT',
        bank: 'ICBC Bank',
        code: 'icbc',
        efd: '',
        otc: '2.35%',
        expired: '30/9/2020',
        date: '',
        link: ''
    },
    {
        id: '17',
        name: '3 / 6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '',
        otc: '1.90% / 2.00% / 2.05%',
        expired: '31/7/2020',
        date: '',
        link: ''
    },
    {
        id: '18',
        name: '3 / 6 / 9 MONTHS FD - 50 YEARS OLD',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.10% / 2.20% / 2.30%',
        expired: '30/9/2020',
        date: '',
        link: 'https://www.pbebank.com/pdf/Promotions/tc_pb-goldenjourney-en.aspx'
    },
    {
        id: '19',
        name: '1 / 2 / 3 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.00% / 2.10% / 2.20%',
        otc: '',
        expired: '30/9/2020',
        date: '',
        link: ''
    },
    {
        id: '20',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.20% / 2.15%',
        expired: '31/7/2020',
        date: '5/7/2020',
        link: ''
    },
    {
        id: '21',
        name: '10 MONTHS eFIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '2.30%',
        otc: '',
        expired: '31/7/2020',
        date: '31/7/2020',
        link: 'https://www.ocbc.com.my/personal-banking/accounts/assets/pdf/OCBC%20Online%20FD%20Promo%20FPX_TC_17July.pdf'
    },
    {
        id: '22',
        name: '9 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '2.35%',
        otc: '',
        expired: '17/8/2020',
        date: '31/7/2020',
        link: 'https://www.ambank.com.my/ambank/SiteAssets/SitePages/promotions/AmOnlineeFDviaFPXPromo/AmOnlineeFDPromoTnC.pdf'
    },
    {
        id: '23',
        name: '3 / 5 / 6 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.45% / 2.50% / 2.45%',
        otc: '',
        expired: '12/8/2020',
        date: '31/7/2020',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/TnC_eFDCampaign_Eng.pdf'
    },
    {
        id: '24',
        name: '3 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.40%',
        otc: '',
        expired: '31/8/2020',
        date: '1/8/2020',
        link: ''
    },
    {
        id: '25',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.15% / 2.10%',
        expired: '7/8/2020',
        date: '3/8/2020',
        link: ''
    },
    {
        id: '26',
        name: '10 MONTHS eFIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '2.18%',
        otc: '',
        expired: '31/8/2020',
        date: '3/8/2020',
        link: 'https://www.ocbc.com.my/personal-banking/accounts/assets/pdf/OCBC_Online_FD_PromoFPX_TC_Aug2020.pdf'
    },
    {
        id: '27',
        name: '3 / 6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '',
        otc: '1.85% / 1.95% / 2.00%',
        expired: '31/8/2020',
        date: '4/8/2020',
        link: ''
    },
    {
        id: '28',
        name: '6 MONTHS OTC & eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.45%',
        otc: '2.45%',
        expired: '22/9/2020',
        date: '7/8/2020',
        link: 'https://www.rhbgroup.com/245/index.html'
    },
    {
        id: '29',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.10% / 2.05%',
        expired: '17/8/2020',
        date: '10/8/2020',
        link: ''
    },
    {
        id: '30',
        name: '3 / 8 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.08% / 2.28%',
        expired: '31/10/2020',
        date: '12/8/2020',
        link: 'https://www.pbebank.com/pdf/Banking/tc_fdtdi-220820-en.aspx'
    },
    {
        id: '31',
        name: '3 / 5 / 6 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.40% / 2.50% / 2.48%',
        otc: '',
        expired: '10/9/2020',
        date: '13/8/2020',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/TnC_eFDCampaign_Eng.pdf'
    },
    {
        id: '32',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.00% / 1.95%',
        expired: '31/8/2020',
        date: '19/8/2020',
        link: ''
    },
    {
        id: '33',
        name: '3 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.30%',
        otc: '',
        expired: '31/10/2020',
        date: '5/9/2020',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/docs/pdf/Promotions/Personal/2020/connect-efd/hlb-efd-i-promo-tnc-en.pdf'
    },
    {
        id: '34',
        name: '7 / 9 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.15% / 2.25%',
        expired: '30/9/2020',
        date: '5/9/2020',
        link: ''
    },
    {
        id: '35',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'Bank Rakyat',
        code: 'br',
        efd: '',
        otc: '2.30%',
        expired: '30/9/2020',
        date: '6/9/2020',
        link: ''
    },
    {
        id: '36',
        name: '5 / 6 / 9 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '1.90% / 2.00% / 2.18% / 2.28%',
        expired: '30/9/2020',
        date: '17/9/2020',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/eFDCampaign2109TnCEng.pdf'
    },
    {
        id: '37',
        name: '12 / 15 MONTHS FIXED DEPOSIT',
        bank: 'MBSB Bank',
        code: 'mbsb',
        efd: '',
        otc: '2.40% / 2.45%',
        expired: '30/9/2020',
        date: '22/9/2020',
        link: ''
    },
    {
        id: '38',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '2.35% / 2.45%',
        expired: '31/10/2020',
        date: '23/9/2020',
        link: ''
    },
    {
        id: '39',
        name: '10 MONTHS eFIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '1.95%',
        otc: '',
        expired: '20/10/2020',
        date: '24/9/2020',
        link: 'https://www.ocbc.com.my/personal-banking/accounts/assets/pdf/OCBC%20Online%20FD%20Promo%20(FPX)%20TC%20Sept20.pdf'
    },
    {
        id: '40',
        name: '5 / 9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.35% / 2.38% / 2.45%',
        otc: '',
        expired: '15/10/2020',
        date: '28/9/2020',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/eFDCampaignTnCEng.pdf'
    },
    {
        id: '41',
        name: '1 / 2 / 3 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.00% / 2.10% / 2.20%',
        otc: '',
        expired: '31/12/2020',
        date: '1/10/2020',
        link: 'https://www.pbebank.com/pdf/Promotions/tc_efd-fpx-1020-en.aspx'
    },
    {
        id: '42',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.10% / 2.20% / 2.25%',
        otc: '',
        expired: '3/11/2020',
        date: '3/10/2020',
        link: 'https://www.cimbclicks.com.my/pdf/tc-efd-2020-eng-oct.pdf'
    },
    {
        id: '43',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.20% / 2.20%',
        otc: '',
        expired: '20/10/2020',
        date: '9/10/2020',
        link: 'https://www.hlb.com.my/content/dam/hlisb/my/docs/pdf/promotions/2020/efd-i-promo-oct-nov/hlisb-6-months-and-12-months-efd-i-promotions-tnc-en.pdf'
    },
    {
        id: '44',
        name: '10 MONTHS eFIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '1.95%',
        otc: '',
        expired: '25/10/2020',
        date: '22/10/2020',
        link: 'https://www.ocbc.com.my/assets/pdf/Accounts/notices/FD_End_25102020.pdf'
    },
    {
        id: '45',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.35% / 2.25%',
        otc: '',
        expired: '31/10/2020',
        date: '22/10/2020',
        link: 'https://www.hlb.com.my/content/dam/hlisb/my/docs/pdf/promotions/2020/efd-i-promo-oct-nov/hlisb-6-months-and-12-months-efd-i-promotions-tnc-en.pdf'
    },
    {
        id: '46',
        name: '6 / 9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.36% / 2.38% / 2.45%',
        otc: '',
        expired: '2/11/2020',
        date: '29/10/2020',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/eFDCampaignTnCEng.pdf'
    },
    {
        id: '47',
        name: '3 / 8 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.08% / 2.28%',
        expired: '31/12/2020',
        date: '1/11/2020',
        link: 'https://www.pbebank.com/pdf/Banking/tc_fdtdi-011120en.aspx'
    },
    {
        id: '48',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.30% / 2.35% / 2.25%',
        otc: '',
        expired: '31/12/2020',
        date: '6/11/2020',
        link: 'https://www.hlb.com.my/content/dam/hlisb/my/docs/pdf/promotions/2020/efd-i-promo-nov-dec/hlisb-3-month-6-month-and-12-month-efd-i-promo-tnc-nov-en.pdf'
    },
    {
        id: '49',
        name: '7 / 9 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.25% / 2.35%',
        expired: '30/11/2020',
        date: '6/11/2020',
        link: ''
    },
    {
        id: '50',
        name: '6 / 9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.36% / 2.38% / 2.45%',
        otc: '',
        expired: '16/11/2020',
        date: '6/11/2020',
        link: ''
    },
    {
        id: '51',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.10% / 2.20% / 2.25%',
        otc: '',
        expired: '20/1/2021',
        date: '12/11/2020',
        link: 'https://www.cimbclicks.com.my/pdf/tc-efd-2020-eng-nov-relaunch.pdf'
    },
    {
        id: '52',
        name: '9 MONTHS FIXED DEPOSIT + CASA',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '2.17% - 2.40%',
        expired: '31/12/2020',
        date: '12/11/2020',
        link: 'https://www.ambank.com.my/ambank/SiteAssets/SitePages/promotions/FDCombinedCASA/FDCombinedCASATnC.pdf'
    },
    {
        id: '53',
        name: '6 / 9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.36% / 2.38% / 2.25%',
        otc: '',
        expired: '30/11/2020',
        date: '21/11/2020',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/eFDCampaignTnCEng.pdf'
    },
    {
        id: '54',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.35%',
        otc: '',
        expired: '30/11/2020',
        date: '21/11/2020',
        link: ''
    },
    {
        id: '55',
        name: '6 / 9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.36% / 2.38% / 2.25%',
        otc: '',
        expired: '31/12/2020',
        date: '3/12/2020',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/eFDCampaignTnCEng.pdf'
    },
    {
        id: '56',
        name: '7 / 9 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.30% / 2.40%',
        expired: '31/12/2020',
        date: '3/12/2020',
        link: ''
    },
    {
        id: '57',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.35%',
        otc: '',
        expired: '31/12/2020',
        date: '3/12/2020',
        link: 'https://www.rhbgroup.com/235/files/TnC_2_6_2.35_eFD_eCMDi.pdf'
    },
    {
        id: '58',
        name: '6 MONTHS FIXED DEPOSIT + CASA',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '2.35%',
        expired: '31/12/2020',
        date: '3/12/2020',
        link: 'https://www.ambank.com.my/ambank/SiteAssets/SitePages/simple/terms-and-conditions/CASAFDTDBundleCampaignTnC.pdf'
    },
    {
        id: '59',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '2.30% - Promo Code: BEST6',
        otc: '',
        expired: '31/12/2020',
        date: '3/12/2020',
        link: ''
    },
    {
        id: '60',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.45%',
        otc: '',
        expired: '31/12/2020',
        date: '23/12/2020',
        link: 'https://www.rhbgroup.com/pdf/TnC_CMDi_2_45_2_35_eTerm_Deposit_2_35.pdf'
    },
    {
        id: '61',
        name: '3 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.35%',
        otc: '',
        expired: '7/1/2021',
        date: '3/1/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/docs/pdf/Promotions/Personal/2020/connect-efd/hlb-new-year-2021-efd-i-promo-tnc-en.pdf'
    },
    {
        id: '62',
        name: '1 / 2 / 3 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.00% / 2.10% / 2.20%',
        otc: '',
        expired: '30/4/2021',
        date: '3/1/2021',
        link: 'https://www.pbebank.com/pdf/Promotions/efd-fpx21-en-tc.aspx'
    },
    {
        id: '63',
        name: '3 / 8 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.08% / 2.28%',
        expired: '14/1/2021',
        date: '3/1/2021',
        link: 'https://www.pbebank.com/pdf/Banking/tc_fdtdi010121-en.aspx'
    },
    {
        id: '64',
        name: '7 / 9 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.10% / 2.20%',
        expired: '31/1/2021',
        date: '4/1/2021',
        link: ''
    },
    {
        id: '65',
        name: '1 / 3 / 6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '',
        otc: '1.50% / 1.90% / 2.00% / 2.10%',
        expired: '31/1/2021',
        date: '5/1/2021',
        link: ''
    },
    {
        id: '66',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '1.90% / 1.85%',
        expired: '31/1/2021',
        date: '5/1/2021',
        link: ''
    },
    {
        id: '67',
        name: '12 / 15 / 24 MONTHS FIXED DEPOSIT',
        bank: 'MBSB Bank',
        code: 'mbsb',
        efd: '',
        otc: '2.40% / 2.45% / 2.55%',
        expired: '31/1/2021',
        date: '6/1/2021',
        link: ''
    },
    {
        id: '68',
        name: '3 / 6 / 9 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '1.95% / 2.15% / 2.10% / 2.20%',
        expired: '19/1/2021',
        date: '6/1/2021',
        link: ''
    },
    {
        id: '69',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.30% / 2.35% / 2.30%',
        otc: '',
        expired: '31/3/2021',
        date: '8/1/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/docs/pdf/Promotions/Personal/2021/efd-jan-march/hlb-efd-and-efd-i-promotion-jan-march-2021-tnc-en.pdf'
    },
    {
        id: '70',
        name: '3 / 8 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '1.88% / 2.08%',
        expired: '24/1/2021',
        date: '15/1/2021',
        link: 'https://www.pbebank.com/pdf/Promotions/pb-fd-td-i-0121-tc-en.aspx'
    },
    {
        id: '71',
        name: '1 / 3 / 6 / 8 / 12 MO. FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.00% / 2.30% / 2.40% / 2.40% / 2.45%',
        expired: '15/2/2021',
        date: '21/1/2021',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/TnC-Oxpicious-Chinese-New-Year-(CNY)-with-Fixed-Deposit-Campaign-ENG.pdf'
    },
    {
        id: '72',
        name: '3 / 6 MO. FIXED DEPOSIT + CASA',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '2.30% - 2.39%',
        expired: '31/3/2021',
        date: '22/1/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/docs/pdf/Promotions/Personal/2021/dp-fd-bundle-promo-jan-march-2021/hlb-3-and-6-month-fd-bundle-casa-promotion-tnc.pdf'
    },
    {
        id: '73',
        name: '3 / 8 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.08% / 2.28%',
        expired: '31/3/2021',
        date: '24/1/2021',
        link: 'https://www.pbebank.com/pdf/Promotions/tc-fdtdi-en250121.aspx'
    },
    {
        id: '74',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.40% / 2.45%',
        otc: '',
        expired: '31/1/2021',
        date: '25/1/2021',
        link: ''
    },
    {
        id: '75',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '2.35% / 2.35%',
        expired: '31/3/2021',
        date: '29/1/2021',
        link: 'https://www.rhbgroup.com/235/files/TnC1_CMDi_2-35_6_or_12_months.pdf'
    },
    {
        id: '76',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.35% / 2.35%',
        otc: '',
        expired: '31/3/2021',
        date: '29/1/2021',
        link: 'https://www.rhbgroup.com/235/files/TnC2_eTerm_Deposit_2-35_6_or_12%20months.pdf'
    },
    {
        id: '77',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '2.38%',
        otc: '',
        expired: '5/2/2021',
        date: '2/2/2021',
        link: ''
    },
    {
        id: '78',
        name: '3 / 6 / 9 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.10% / 2.20% / 2.30%',
        expired: '30/6/2021',
        date: '2/2/2021',
        link: 'https://www.pbebank.com/pdf/Promotions/journey-golden-en-tc.aspx'
    },
    {
        id: '79',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.28%',
        expired: '31/3/2021',
        date: '6/2/2021',
        link: ''
    },
    {
        id: '80',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.10% / 2.20% / 2.25%',
        otc: '',
        expired: '4/3/2021',
        date: '10/2/2021',
        link: 'https://www.cimbclicks.com.my/pdf/tc-efd-feb21-en.pdf'
    },
    {
        id: '81',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.40%',
        otc: '',
        expired: '26/2/2021',
        date: '11/2/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/connect-efd/cny-exclusive/hlb-efd-and-efd-cny-exclusive-promotion-tnc-en.pdf'
    },
    {
        id: '82',
        name: '3 / 6 / 8 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.20% / 2.35% / 2.35% / 2.38%',
        otc: '',
        expired: '28/2/2021',
        date: '18/2/2021',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/FDTDiABBTnCEng.pdf'
    },
    {
        id: '83',
        name: '12 / 15 / 18 MONTHS FIXED DEPOSIT',
        bank: 'MBSB Bank',
        code: 'mbsb',
        efd: '',
        otc: '2.40% / 2.45% / 2.50%',
        expired: '31/3/2021',
        date: '5/3/2021',
        link: ''
    },
    {
        id: '84',
        name: '7 / 9 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.05% / 2.15%',
        expired: '31/3/2021',
        date: '7/3/2021',
        link: ''
    },
    {
        id: '85',
        name: '1 / 3 / 6 / 8 / 12 MO. eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '1.90% / 2.20% / 2.35% / 2.35% / 2.35%',
        otc: '',
        expired: '31/3/2021',
        date: '8/3/2021',
        link: ''
    },
    {
        id: '86',
        name: '3 MONTHS FIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '3.00%',
        expired: '31/3/2021',
        date: '14/3/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/dp-3-months-fd-promotion-new-customers/hlb-3-month-fd-and-fd-i-promo-for-new-customers-tnc-en.pdf'
    },
    {
        id: '87',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.05% / 2.15% / 2.20%',
        otc: '',
        expired: '6/5/2021',
        date: '24/3/2021',
        link: 'https://www.cimbclicks.com.my/pdf/tc-efd-mar21-en.pdf'
    },
    {
        id: '88',
        name: '9 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '2.50% - Promo Code: BEST9',
        otc: '',
        expired: '31/5/2021',
        date: '31/3/2021',
        link: 'https://www.ambank.com.my/ambank/SiteAssets/SitePages/promotions/AmOnlineeFDviaFPXPromo/AmOnlineeFDviaFPXTnC.pdf'
    },
    {
        id: '89',
        name: '18 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '2.70% - Promo Code: BEST18',
        otc: '',
        expired: '31/5/2021',
        date: '31/3/2021',
        link: 'https://www.ambank.com.my/ambank/SiteAssets/SitePages/promotions/AmOnlineeFDviaFPXPromo/AmOnlineeFDviaFPXTnC.pdf'
    },
    {
        id: '90',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.30% / 2.35% / 2.35%',
        otc: '',
        expired: '30/6/2021',
        date: '1/4/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/docs/pdf/Promotions/Personal/2020/connect-efd/hlb-efd-i-promo-tnc-en.pdf'
    },
    {
        id: '91',
        name: '18 / 24 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.40% / 2.40%',
        otc: '',
        expired: '30/6/2021',
        date: '1/4/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/docs/pdf/Promotions/Personal/2020/connect-efd/hlb-efd-i-promo-tnc-en.pdf'
    },
    {
        id: '92',
        name: '7 / 9 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.20% / 2.30%',
        expired: '30/4/2021',
        date: '1/4/2021',
        link: ''
    },
    {
        id: '93',
        name: '3 / 6 / 12 MO. FIXED DEPOSIT - CASA',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.21% / 2.27% / 2.36%',
        expired: '5/5/2021',
        date: '4/4/2021',
        link: 'https://www.affinonline.com/AFFINONLINE/media/assets/promotions%20documents/TnC_ABB_RiangRaya_ENG.pdf'
    },
    {
        id: '94',
        name: '7 / 9 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.30% / 2.40%',
        expired: '31/5/2021',
        date: '3/5/2021',
        link: ''
    },
    {
        id: '95',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '2.35%',
        expired: '31/5/2021',
        date: '3/5/2021',
        link: ''
    },
    {
        id: '96',
        name: '8 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.33%',
        expired: '31/5/2021',
        date: '4/5/2021',
        link: ''
    },
    {
        id: '97',
        name: '8 / 11 / 18 MONTHS FIXED DEPOSIT',
        bank: 'Bank Rakyat',
        code: 'br',
        efd: '',
        otc: '2.48% / 2.58% / 2.78%',
        expired: '31/8/2021',
        date: '11/5/2021',
        link: ''
    },
    {
        id: '98',
        name: '3 / 6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '2.20% / 2.25% / 2.35%',
        expired: '28/5/2021',
        date: '12/5/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/dp-fd-raya/hlb-fixed-deposit-raya-promotion-2021-tnc-en.pdf'
    },
    {
        id: '99',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.40% / 2.40%',
        otc: '',
        expired: '28/5/2021',
        date: '13/5/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/docs/pdf/Promotions/Personal/2020/connect-efd/hlb-efd-i-raya-promo-tnc-en.pdf'
    },
    {
        id: '100',
        name: '7 / 9 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.25% / 2.35%',
        expired: '30/6/2021',
        date: '1/6/2021',
        link: ''
    },
    {
        id: '101',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '1.90% / 1.90%',
        expired: '30/6/2021',
        date: '1/6/2021',
        link: ''
    },
    {
        id: '102',
        name: '9 / 18 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '2.50% / 2.65%',
        expired: '30/6/2021',
        date: '3/6/2021',
        link: ''
    },
    {
        id: '103',
        name: '3 / 6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.05% / 2.25% / 2.55%',
        expired: '20/6/2021',
        date: '4/6/2021',
        link: ''
    },
    {
        id: '104',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.00% / 2.10% / 2.15%',
        otc: '',
        expired: '9/9/2021',
        date: '4/6/2021',
        link: 'https://www.cimb.com.my/content/dam/cimb/personal/documents/tnc/promotions/efria/efria-via-clicks-campaign-tnc-eng.pdf'
    },
    {
        id: '105',
        name: '1 / 2 / 3 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.00% / 2.10% / 2.20%',
        otc: '',
        expired: '30/6/2021',
        date: '6/6/2021',
        link: ''
    },
    {
        id: '106',
        name: '3 / 8 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.08% / 2.28%',
        expired: '30/9/2021',
        date: '30/6/2021',
        link: 'https://www.pbebank.com/pdf/Banking/tc_fdtdi300921-en.aspx'
    },
    {
        id: '107',
        name: '6 MONTHS eFIXED DEPOSIT - 7.7',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.50%',
        otc: '',
        expired: '8/7/2021',
        date: '1/7/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/connect-efd/july-1-day/hlb-1-day-efd-efd-i-promotion-tnc-en.pdf'
    },
    {
        id: '108',
        name: '6 MONTHS eFIXED DEPOSIT - 7.7',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.70% - New Cust. Only',
        otc: '',
        expired: '8/7/2021',
        date: '1/7/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/connect-efd/july-1-day-new/hlb-1-day-new-customer-efd-efd-i-promotion-tnc-en.pdf'
    },
    {
        id: '109',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.35% / 2.40% / 2.45%',
        otc: '',
        expired: '31/7/2021',
        date: '1/7/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/docs/pdf/Promotions/Personal/2021/connect-efd/july/hlb-efd-i-promo-tnc-en.pdf'
    },
    {
        id: '110',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.20% / 2.30%',
        expired: '31/7/2021',
        date: '1/7/2021',
        link: ''
    },
    {
        id: '111',
        name: '12 / 15 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.50% / 2.55%',
        otc: '2.50% / 2.55%',
        expired: '31/8/2021',
        date: '1/7/2021',
        link: 'https://www.rhbgroup.com/255/files/TnC-eFD-eCMDi-12mo-and-15mo-Campaign-2021_1st-July21.pdf'
    },
    {
        id: '112',
        name: '1 / 2 / 3 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.00% / 2.10% / 2.20%',
        otc: '',
        expired: '30/9/2021',
        date: '1/7/2021',
        link: 'https://www.pbebank.com/pdf/Promotions/tc_efdviafpx010721en.aspx'
    },
    {
        id: '113',
        name: '6 / 9 MONTHS FIXED DEPOSIT - 50SA',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.20% / 2.23%',
        expired: '31/12/2021',
        date: '2/7/2021',
        link: 'https://www.pbebank.com/pdf/Promotions/journey-golden-en-tc.aspx'
    },
    {
        id: '114',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '1.85% / 2.00%',
        expired: '31/7/2021',
        date: '9/7/2021',
        link: ''
    },
    {
        id: '115',
        name: '6 / 12 / 15 / 24 MO. FIXED DEPOSIT',
        bank: 'MBSB Bank',
        code: 'mbsb',
        efd: '',
        otc: '2.20% / 2.40% / 2.45% / 2.55%',
        expired: '31/8/2021',
        date: '27/7/2021',
        link: ''
    },
    {
        id: '116',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '2.40% - Code: BEST6',
        otc: '',
        expired: '31/8/2021',
        date: '27/7/2021',
        link: 'https://www.ambank.com.my/ambank/SiteAssets/SitePages/simple/terms-and-conditions/AmOnlineeFDviaFPXTnC.pdf'
    },
    {
        id: '117',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.50%',
        otc: '',
        expired: '31/8/2021',
        date: '31/7/2021',
        link: ''
    },
    {
        id: '118',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '1.85% / 2.00%',
        expired: '31/8/2021',
        date: '2/8/2021',
        link: ''
    },
    {
        id: '119',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.35% / 2.40%',
        otc: '',
        expired: '31/8/2021',
        date: '2/8/2021',
        link: ''
    },
    {
        id: '120',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.20% / 2.30%',
        expired: '31/8/2021',
        date: '3/8/2021',
        link: ''
    },
    {
        id: '121',
        name: '6 MONTHS FIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '2.40%',
        expired: '30/9/2021',
        date: '10/8/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/step-up-fd-and-fd-i-promotion/hlb-6-month-step-up-fd-and-fd-i-promotion-tnc-en.pdf'
    },
    {
        id: '122',
        name: '6 / 12 / 18 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.35% / 2.40% / 2.40%',
        otc: '',
        expired: '30/9/2021',
        date: '13/8/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/connect-efd/aug-sept/hlb-efd-i-promo-tnc-en.pdf'
    },
    {
        id: '123',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.55% - INVIKTA Only',
        expired: '31/8/2021',
        date: '24/8/2021',
        link: ''
    },
    {
        id: '124',
        name: '6 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.10%',
        expired: '30/9/2021',
        date: '1/9/2021',
        link: ''
    },
    {
        id: '125',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.35% / 2.55% - INVIKTA Only',
        expired: '8/9/2021',
        date: '2/9/2021',
        link: ''
    },
    {
        id: '126',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.30% / 2.50%',
        expired: '8/9/2021',
        date: '2/9/2021',
        link: ''
    },
    {
        id: '127',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.30% / 2.50%',
        otc: '',
        expired: '8/9/2021',
        date: '2/9/2021',
        link: ''
    },
    {
        id: '128',
        name: '1 MONTHS FD - 50 YEARS OLD',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '3.00%',
        expired: '31/1/2022',
        date: '3/9/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/dp-fd-exclusive-promotion-for-aged-50-and-above/hlb-fixed-deposit-exclusive-promotion-for-aged-50-and-above-tnc.pdf'
    },
    {
        id: '129',
        name: '3 MONTHS FD - BUNDLE SA',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '3.08%',
        expired: '31/1/2022',
        date: '3/9/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/dp-new-to-priority-banking-fd-casa-bundle-2021/hlb-new-to-pb-campaign-tnc-en.pdf'
    },
    {
        id: '130',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '',
        otc: '2.40% / 2.55%',
        expired: '31/10/2021',
        date: '3/9/2021',
        link: ''
    },
    {
        id: '131',
        name: '6 / 9 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.40% / 2.40%',
        otc: '',
        expired: '30/11/2021',
        date: '13/9/2021',
        link: 'https://www.rhbgroup.com/240/files/TnC_6mo_9mo_FPX_10_sept_21.pdf'
    },
    {
        id: '132',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.15% / 2.30%',
        expired: '30/9/2021',
        date: '17/9/2021',
        link: ''
    },
    {
        id: '133',
        name: '6 / 9 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '2.40% / 2.40%',
        expired: '30/11/2021',
        date: '19/9/2021',
        link: 'https://www.rhbgroup.com/240/files/TnC_6_9_FDCMDi_10_sept_21.pdf'
    },
    {
        id: '134',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '2.40% - Code: BEST6',
        otc: '',
        expired: '31/10/2021',
        date: '20/9/2021',
        link: 'https://www.ambank.com.my/ambank/SiteAssets/SitePages/simple/terms-and-conditions/AmOnlineeFDviaFPXTnC.pdf'
    },
    {
        id: '135',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.10% / 2.20% / 2.30%',
        otc: '',
        expired: '3/11/2021',
        date: '29/9/2021',
        link: 'https://www.cimb.com.my/content/dam/cimb/personal/documents/tnc/promotions/efria/efria-via-clicks-campaign-tnc-eng.pdf'
    },
    {
        id: '136',
        name: '1 / 2 / 3 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.00% / 2.10% / 2.20%',
        otc: '',
        expired: '31/12/2021',
        date: '1/10/2021',
        link: 'https://www.pbebank.com/pdf/Promotions/en/efdfpxntnc.aspx'
    },
    {
        id: '137',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.45%',
        otc: '',
        expired: '31/12/2021',
        date: '2/10/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/dp-new-to-efd-campaign-oct-dec-2021/hlb-new-to-efd-campaign-oct-dec-2021-tnc.pdf'
    },
    {
        id: '138',
        name: '12 / 18 / 24 MONTHS FIXED DEPOSIT',
        bank: 'MBSB Bank',
        code: 'mbsb',
        efd: '',
        otc: '2.40% / 2.50% / 2.55%',
        expired: '31/10/2021',
        date: '5/10/2021',
        link: ''
    },
    {
        id: '139',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.30% / 2.35% / 2.40%',
        otc: '',
        expired: '31/12/2021',
        date: '6/10/2021',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2021/connect-efd/oct-dec/hlb-efd-i-promo-tnc-en.pdf'
    },
    {
        id: '140',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.55%',
        otc: '',
        expired: '19/10/2021',
        date: '8/10/2021',
        link: 'https://www.rhbgroup.com/etdflashpromo/files/TnC_12_2_55_eFDeCMDi_special_day_v2.pdf'
    },
    {
        id: '141',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.15% / 2.30%',
        expired: '30/11/2021',
        date: '2/11/2021',
        link: ''
    },
    {
        id: '142',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.50% / 2.55%',
        otc: '',
        expired: '30/11/2021',
        date: '2/11/2021',
        link: ''
    },
    {
        id: '143',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.55%',
        otc: '',
        expired: '21/11/2021',
        date: '8/11/2021',
        link: 'https://www.rhbgroup.com/etdflashpromo/files/TnC_12-2-55-eFDeCMDi-special-day_11-11-Promo.pdf'
    },
    {
        id: '144',
        name: '9 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '2.40% - Code: BEST9',
        otc: '',
        expired: '31/12/2021',
        date: '16/11/2021',
        link: 'https://www.ambank.com.my/ambank/SiteAssets/SitePages/simple/terms-and-conditions/AmOnlineeFDviaFPXTnC.pdf'
    },
    {
        id: '145',
        name: '8 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.38%',
        otc: '',
        expired: '15/1/2022',
        date: '1/12/2021',
        link: 'https://www.rhbgroup.com/238/files/RHB-e-Term_Deposit_2-38_for_8_months.pdf'
    },
    {
        id: '146',
        name: '3 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '2.45% - Code: BEST3',
        otc: '',
        expired: '31/12/2021',
        date: '3/12/2021',
        link: 'https://www.ambank.com.my/ambank/SiteAssets/SitePages/simple/terms-and-conditions/AmOnlineeFDviaFPXTnC.pdf'
    },
    {
        id: '147',
        name: '4 / 6 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '1.85% / 2.10%',
        expired: '31/12/2021',
        date: '7/12/2021',
        link: ''
    },
    {
        id: '148',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.55%',
        otc: '',
        expired: '31/12/2021',
        date: '12/12/2021',
        link: 'https://www.rhbgroup.com/etdflashpromo/files/TnC_12_2-55_eFDeCMDi-special-day_12-12_v1.pdf'
    },
    {
        id: '149',
        name: '12 / 18 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '2.65% / 2.70% - CASA',
        otc: '',
        expired: '31/12/2021',
        date: '24/12/2021',
        link: ''
    },
    {
        id: '150',
        name: '6 / 12 / 24 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.30% / 2.40% / 2.45%',
        otc: '',
        expired: '31/3/2022',
        date: '1/1/2022',
        link: ''
    },
    {
        id: '151',
        name: '6 / 9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.25% / 2.30% / 2.35%',
        otc: '',
        expired: '17/4/2022',
        date: '1/1/2022',
        link: 'https://www.pbebank.com/pdf/Banking/tc_fd-tdi010122-en.aspx'
    },
    {
        id: '152',
        name: '9 / 12 / 18 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.50% / 2.55% / 2.60%',
        expired: '20/1/2022',
        date: '1/1/2022',
        link: ''
    },
    {
        id: '153',
        name: '6 MONTHS FIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '2.40%',
        expired: '31/3/2022',
        date: '3/1/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/step-up-fd-and-fd-i-promotion/hlb-6-month-step-up-fd-and-fd-i-promotion-tnc-en.pdf'
    },
    {
        id: '154',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.45% - New Customers',
        otc: '',
        expired: '31/3/2022',
        date: '3/1/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/dp-new-to-efd-campaign/hlb-new-to-efd-campaign-jan-mar-2021-tnc-en.pdf'
    },
    {
        id: '155',
        name: '4 / 6 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '1.85% / 2.10%',
        expired: '31/1/2022',
        date: '3/1/2022',
        link: ''
    },
    {
        id: '156',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.10% / 2.30%',
        expired: '31/1/2022',
        date: '3/1/2022',
        link: ''
    },
    {
        id: '157',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.48%',
        otc: '',
        expired: '28/2/2022',
        date: '18/1/2022',
        link: ''
    },
    {
        id: '158',
        name: '6 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.28%',
        expired: '7/2/2022',
        date: '28/1/2022',
        link: ''
    },
    {
        id: '159',
        name: '6 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.18%',
        expired: '31/3/2022',
        date: '28/1/2022',
        link: ''
    },
    {
        id: '160',
        name: '38 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.80% - Invikta',
        expired: '15/2/2022',
        date: '30/1/2022',
        link: ''
    },
    {
        id: '161',
        name: '18 / 24 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.65% / 2.80%',
        otc: '',
        expired: '28/2/2022',
        date: '1/2/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/connect-efd/feb/hlb-efd-i-promo-tnc-en.pdf'
    },
    {
        id: '162',
        name: '36 MONTHS FIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '2.77%',
        expired: '28/2/2022',
        date: '1/2/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/step-up-fd-and-fd-i-promotion/hlb-6-month-step-up-fd-and-fd-i-promotion-tnc-en.pdf'
    },
    {
        id: '163',
        name: '6 / 9 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.48% / 2.48%',
        otc: '',
        expired: '31/3/2022',
        date: '16/2/2022',
        link: 'https://www.rhbgroup.com/pdf/TnC_6mo_9mo_FPX_CASA_248.pdf'
    },
    {
        id: '164',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '2.55%',
        otc: '',
        expired: '30/6/2022',
        date: '22/2/2022',
        link: 'https://www.alliancebank.com.my/Alliance/media/Documents/Promotion/Coolest-Cashback-eFD-Campaign-tc.pdf?utm_source=alliance-edm&utm_medium=edm&utm_campaign=PBAP-monthly-e-newsletter-202201'
    },
    {
        id: '165',
        name: '18 / 24 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.60% / 2.75%',
        otc: '',
        expired: '31/3/2022',
        date: '1/3/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/connect-efd/mar/hlb-efd-i-promo-tnc-en.pdf'
    },
    {
        id: '166',
        name: '6 / 26 MONTHS FIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '2.40% / 2.72%',
        expired: '31/3/2022',
        date: '1/3/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/step-up-fd-and-fd-i-promotion/hlb-36-month-step-up-fd-and-fd-i-promotion-tnc-en.pdf'
    },
    {
        id: '167',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.10% / 2.45%',
        expired: '31/3/2022',
        date: '1/3/2022',
        link: ''
    },
    {
        id: '168',
        name: '12 / 24 MONTHS FIXED DEPOSIT',
        bank: 'Bank Rakyat',
        code: 'br',
        efd: '',
        otc: '2.60% / 2.80%',
        expired: '9/7/2022',
        date: '9/3/2022',
        link: ''
    },
    {
        id: '169',
        name: '15 / 18 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.55% / 2.65%',
        otc: '',
        expired: '10/7/2022',
        date: '29/3/2022',
        link: 'https://www.publicislamicbank.com.my/pdf/Announcement/Highlights/PIBB-15M-18M-TD-i-T-C_ENG.aspx'
    },
    {
        id: '170',
        name: '3 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.35%',
        otc: '',
        expired: '31/3/2022',
        date: '31/3/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/efd-and-efdi-payday-promotion/hlb-efd-and-efd-i-payday-march-2022-tnc-en.pdf'
    },
    {
        id: '171',
        name: '9 / 13 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.40% / 2.50%',
        expired: '30/4/2022',
        date: '1/4/2022',
        link: ''
    },
    {
        id: '172',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.10% / 2.20% / 2.30%',
        otc: '',
        expired: '31/5/2022',
        date: '14/4/2022',
        link: 'https://www.cimb.com.my/content/dam/cimb/personal/documents/tnc/promotions/efria/efria-via-clicks-campaign-tnc-eng.pdf'
    },
    {
        id: '173',
        name: '6 / 9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.35% / 2.40% / 2.45%',
        otc: '',
        expired: '30/4/2022',
        date: '18/4/2022',
        link: 'https://www.pbebank.com/pdf/Promotions/en/efdfpxntnc.aspx'
    },
    {
        id: '174',
        name: '3 / 6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.00% / 2.25%',
        otc: '',
        expired: '31/5/2022',
        date: '8/5/2022',
        link: ''
    },
    {
        id: '175',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.15% / 2.50%',
        expired: '23/5/2022',
        date: '13/5/2022',
        link: ''
    },
    {
        id: '176',
        name: '15 / 18 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.60% / 2.70%',
        otc: '',
        expired: '10/7/2022',
        date: '16/5/2022',
        link: ''
    },
    {
        id: '177',
        name: '3 / 6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '2.40% / 2.50% / 2.65%',
        expired: '31/5/2022',
        date: '23/5/2022',
        link: 'https://www.rhbgroup.com/smefd/files/Terms_Conditions_3_6_12_Months_FD_or_CMD-i_Campaign_2022.pdf'
    },
    {
        id: '178',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.75%',
        expired: '30/6/2022',
        date: '24/5/2022',
        link: ''
    },
    {
        id: '179',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.80%',
        expired: '4/6/2022',
        date: '1/6/2022',
        link: 'https://www.uob.com.my/web-resources/personal/pdf/personal/save/savings/saturday-banking-special-fd-plus-promotion-tnc.pdf'
    },
    {
        id: '180',
        name: '9 / 13 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.55% / 2.65%',
        expired: '30/6/2022',
        date: '1/6/2022',
        link: ''
    },
    {
        id: '181',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.45%',
        otc: '',
        expired: '30/6/2022',
        date: '1/6/2022',
        link: ''
    },
    {
        id: '182',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '2.73%',
        otc: '',
        expired: '30/6/2022',
        date: '1/6/2022',
        link: ''
    },
    {
        id: '183',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.85%',
        expired: '30/6/2022',
        date: '1/6/2022',
        link: ''
    },
    {
        id: '184',
        name: '7 / 13 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.30% / 2.60%',
        expired: '10/7/2022',
        date: '14/6/2022',
        link: 'https://www.publicislamicbank.com.my/pdf/Announcement/Highlights/PIBB-7M-13M-TD-i-T-C_ENG.aspx'
    },
    {
        id: '185',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.35% / 2.45% / 2.55%',
        otc: '',
        expired: '17/7/2022',
        date: '15/6/2022',
        link: 'https://www.cimb.com.my/content/dam/cimb/personal/documents/tnc/promotions/efria/efria-via-clicks-campaign-tnc-eng.pdf'
    },
    {
        id: '186',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '2.95%',
        expired: '1/7/2022',
        date: '24/6/2022',
        link: ''
    },
    {
        id: '187',
        name: '13 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.65%',
        expired: '6/7/2022',
        date: '24/6/2022',
        link: ''
    },
    {
        id: '188',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.00%',
        otc: '',
        expired: '7/7/2022',
        date: '1/7/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/digital-day/deposits/efd-efd-i-7-7/hlb-new-to-efd-7-7campaign-tnc-en.pdf'
    },
    {
        id: '189',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.60%',
        otc: '',
        expired: '31/7/2022',
        date: '1/7/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/dp-new-to-efd-campaign/hlb-cd-new-to-efd-campaign-tnc-en.pdf'
    },
    {
        id: '190',
        name: '24 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.10%',
        otc: '',
        expired: '31/7/2022',
        date: '1/7/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/connect-efd/june/hlb-efd-and-efd-i-tnc-en.pdf'
    },
    {
        id: '191',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '2.85%',
        expired: '31/7/2022',
        date: '1/7/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/step-up-fd-and-fd-i-promotion/apr-june/hlb-step-up-fd-fd-i-tnc-en.pdf'
    },
    {
        id: '192',
        name: '9 / 12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '2.90% / 3.20%',
        expired: '31/7/2022',
        date: '6/7/2022',
        link: ''
    },
    {
        id: '193',
        name: '9 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '2.80% / 3.10%',
        expired: '31/7/2022',
        date: '8/7/2022',
        link: ''
    },
    {
        id: '194',
        name: '18 / 24 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '3.05% / 3.15%',
        otc: '',
        expired: '31/7/2022',
        date: '8/7/2022',
        link: ''
    },
    {
        id: '195',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.75% / 2.80%',
        otc: '',
        expired: '10/7/2022',
        date: '8/7/2022',
        link: ''
    },
    {
        id: '196',
        name: '15 / 18 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '3.20% / 3.30%',
        otc: '',
        expired: '30/7/2022',
        date: '11/7/2022',
        link: 'https://www.publicislamicbank.com.my/pdf/Terms-Conditions-(1)/eTD-i-FPX-Camp-ENG.aspx'
    },
    {
        id: '197',
        name: '15 / 18 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '3.20% / 3.30%',
        expired: '30/7/2022',
        date: '11/7/2022',
        link: 'https://www.publicislamicbank.com.my/pdf/Announcement/Highlights/PIBB-15M-18M-TD-i-T-C_ENG.aspx'
    },
    {
        id: '198',
        name: '7 / 13 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.70% / 3.00%',
        expired: '31/8/2022',
        date: '11/7/2022',
        link: 'https://www.publicislamicbank.com.my/pdf/Announcement/Highlights/PIBB-7M-13M-TD-i-T-C_ENG.aspx'
    },
    {
        id: '199',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.55% / 2.75%',
        expired: '31/7/2022',
        date: '17/7/2022',
        link: ''
    },
    {
        id: '200',
        name: '3 / 6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.40% / 2.60% / 2.85%',
        otc: '',
        expired: '15/9/2022',
        date: '18/7/2022',
        link: 'https://www.cimb.com.my/content/dam/cimb/personal/documents/tnc/promotions/efria/efria-via-clicks-campaign-tnc-eng.pdf'
    },
    {
        id: '201',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.70% / 3.05%',
        otc: '',
        expired: '31/8/2022',
        date: '1/8/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/connect-efd/june/hlb-efd-and-efd-i-tnc-en.pdf'
    },
    {
        id: '202',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '2.85%',
        otc: '',
        expired: '31/8/2022',
        date: '1/8/2022',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/dp-new-to-efd-campaign/hlb-cd-new-to-efd-campaign-tnc-en.pdf'
    },
    {
        id: '203',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.55% / 2.75%',
        expired: '31/8/2022',
        date: '1/8/2022',
        link: ''
    },
    {
        id: '204',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.55% / 2.85%',
        expired: '31/8/2022',
        date: '1/8/2022',
        link: ''
    },
    {
        id: '205',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '3.20%',
        expired: '31/8/2022',
        date: '1/8/2022',
        link: ''
    },
    {
        id: '206',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'Bank Rakyat',
        code: 'br',
        efd: '',
        otc: '3.30%',
        expired: '30/9/2022',
        date: '8/8/2022',
        link: 'https://www.bankrakyat.com.my/d/campaigns/campaigns/kempen_deposit_i_berjangka_individu_ogos_2022-1657/dwterms/Terms%20Conditions_Terma%20Dan%20Syarat%20ENGLISH.pdf'
    },
    {
        id: '207',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.18% - 1 day only',
        otc: '',
        expired: '17/8/2022',
        date: '16/8/2022',
        link: ''
    },
    {
        id: '208',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '3.20% - BEST12',
        otc: '',
        expired: '11/9/2022',
        date: '9/9/2022',
        link: ''
    },
    {
        id: '209',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '2.75% / 2.80%',
        otc: '',
        expired: '28/11/2022',
        date: '9/9/2022',
        link: 'https://www.pbebank.com/pdf/Promotions/en/efdfpxntnc.aspx'
    },
    {
        id: '210',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '2.80% / 3.20%',
        expired: '30/9/2022',
        date: '9/9/2022',
        link: ''
    },
    {
        id: '211',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.15%',
        otc: '',
        expired: '30/9/2022',
        date: '9/9/2022',
        link: ''
    },
    {
        id: '212',
        name: '3 / 6 MONTHS FIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '',
        otc: '2.50% / 2.70%',
        expired: '30/9/2022',
        date: '18/9/2022',
        link: ''
    },
    {
        id: '213',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '3.30% / 3.50%',
        expired: '30/9/2022',
        date: '18/9/2022',
        link: ''
    },
    {
        id: '214',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '2.55% / 2.60%',
        otc: '',
        expired: '30/9/2022',
        date: '18/9/2022',
        link: ''
    },
    {
        id: '215',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '3.30% / 3.45%',
        expired: '30/9/2022',
        date: '18/9/2022',
        link: ''
    },
    {
        id: '216',
        name: '9 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '3.30% / 3.40%',
        expired: '30/9/2022',
        date: '18/9/2022',
        link: ''
    },
    {
        id: '217',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '2.80% / 3.00%',
        expired: '30/9/2022',
        date: '18/9/2022',
        link: ''
    },
    {
        id: '218',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.80% / 3.00%',
        expired: '28/11/2022',
        date: '25/9/2022',
        link: 'https://www.publicislamicbank.com.my/getattachment/Announcements/News-Announcements/Announcements/Revision-of-Promotional-Rates-for-PIBB-Term-Deposi/T-CTD-iPlusENGv2.pdf.aspx'
    },
    {
        id: '219',
        name: '15 / 18 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '3.40% / 3.50%',
        otc: '',
        expired: '31/10/2022',
        date: '25/9/2022',
        link: 'https://www.publicislamicbank.com.my/pdf/Terms-Conditions-(1)/eTD-i-FPX-Camp-ENG.aspx'
    },
    {
        id: '220',
        name: '7 / 13 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '2.90% / 3.20%',
        expired: '28/11/2022',
        date: '25/9/2022',
        link: 'https://www.publicislamicbank.com.my/getattachment/Announcements/News-Announcements/Announcements/Revision-of-Promotional-Rates-for-PIBB-Term-Deposi/T-CTD-i713ENGv2.pdf.aspx'
    },
    {
        id: '221',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '3.25% / 3.40%',
        otc: '',
        expired: '31/10/2022',
        date: '4/10/2022',
        link: ''
    },
    {
        id: '222',
        name: '9 / 12 MONTHS FIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '',
        otc: '3.05% / 3.10%',
        expired: '31/10/2022',
        date: '4/10/2022',
        link: ''
    },
    {
        id: '223',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '3.50% - MSIA12',
        otc: '',
        expired: '31/10/2022',
        date: '4/10/2022',
        link: ''
    },
    {
        id: '224',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.05% / 3.30%',
        otc: '',
        expired: '3/11/2022',
        date: '13/10/2022',
        link: 'https://www.cimb.com.my/content/dam/cimb/personal/documents/tnc/promotions/efria/efria-sept22-campaign-tnc-en.pdf'
    },
    {
        id: '225',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '3.45% / 3.55%',
        expired: '31/10/2022',
        date: '13/10/2022',
        link: ''
    },
    {
        id: '226',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.75% / 3.95%',
        otc: '',
        expired: '30/11/2022',
        date: '11/11/2022',
        link: ''
    },
    {
        id: '227',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '3.50% / 3.85%',
        otc: '',
        expired: '28/11/2022',
        date: '11/11/2022',
        link: ''
    },
    {
        id: '228',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '3.05% / 3.25%',
        expired: '30/11/2022',
        date: '11/11/2022',
        link: ''
    },
    {
        id: '229',
        name: '15 / 18 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '4.00% / 4.20%',
        expired: '28/11/2022',
        date: '11/11/2022',
        link: ''
    },
    {
        id: '230',
        name: '6 MONTHS FIXED DEPOSIT',
        bank: 'Bank Rakyat',
        code: 'br',
        efd: '',
        otc: '4.05%',
        expired: '31/1/2023',
        date: '11/11/2022',
        link: ''
    },
    {
        id: '231',
        name: '9 / 13 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '3.69% / 3.79%',
        expired: '31/1/2023',
        date: '11/11/2022',
        link: ''
    },
    {
        id: '232',
        name: '8 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '3.80% - BEST8',
        otc: '',
        expired: '30/11/2022',
        date: '11/11/2022',
        link: ''
    },
    {
        id: '233',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '3.50% / 4.05%',
        expired: '31/12/2022',
        date: '28/11/2022',
        link: ''
    },
    {
        id: '234',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '3.80% / 4.00%',
        expired: '31/12/2022',
        date: '28/11/2022',
        link: ''
    },
    {
        id: '235',
        name: '7 / 13 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '3.70% / 4.10%',
        expired: '31/12/2022',
        date: '28/11/2022',
        link: ''
    },
    {
        id: '236',
        name: '15 / 18 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '4.30% / 4.50%',
        expired: '31/12/2022',
        date: '28/11/2022',
        link: ''
    },
    {
        id: '237',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '4.00% / 4.15%',
        otc: '',
        expired: '31/12/2022',
        date: '28/11/2022',
        link: ''
    },
    {
        id: '238',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.85% / 4.05%',
        otc: '',
        expired: '4/1/2023',
        date: '10/12/2022',
        link: ''
    },
    {
        id: '239',
        name: '18 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '4.50% - BEST18',
        otc: '',
        expired: '31/12/2022',
        date: '10/12/2022',
        link: ''
    },
    {
        id: '240',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '4.15%',
        otc: '',
        expired: '31/12/2022',
        date: '27/12/2022',
        link: ''
    },
    {
        id: '241',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '4.05% / 4.25%',
        otc: '',
        expired: '31/3/2023',
        date: '1/1/2023',
        link: 'https://www.pbebank.com/pdf/Promotions/bm/efdfpxntnc.aspx'
    },
    {
        id: '242',
        name: '3 / 8 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.58% / 3.98%',
        otc: '',
        expired: '29/1/2023',
        date: '14/1/2023',
        link: ''
    },
    {
        id: '243',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '4.13%',
        otc: '',
        expired: '15/2/2023',
        date: '14/1/2023',
        link: ''
    },
    {
        id: '244',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '4.05%',
        expired: '31/1/2023',
        date: '14/1/2023',
        link: ''
    },
    {
        id: '245',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '4.20%',
        otc: '',
        expired: '28/2/2023',
        date: '3/2/2023',
        link: ''
    },
    {
        id: '246',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '4.30%',
        expired: '28/2/2023',
        date: '3/2/2023',
        link: ''
    },
    {
        id: '247',
        name: '18 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '4.50%',
        otc: '',
        expired: '28/2/2023',
        date: '3/2/2023',
        link: ''
    },
    {
        id: '248',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '4.15%',
        expired: '28/2/2023',
        date: '3/2/2023',
        link: ''
    },
    {
        id: '249',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '4.20%',
        otc: '',
        expired: '14/3/2023',
        date: '3/2/2023',
        link: ''
    },
    {
        id: '250',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '4.15%',
        otc: '',
        expired: '21/2/2023',
        date: '5/2/2023',
        link: ''
    },
    {
        id: '251',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '4.10% / 4.25%',
        otc: '',
        expired: '21/3/2023',
        date: '11/3/2023',
        link: ''
    },
    {
        id: '252',
        name: '3 / 6 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '4.05% / 4.10%',
        otc: '',
        expired: '31/3/2023',
        date: '11/3/2023',
        link: ''
    },
    {
        id: '253',
        name: '8 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.98% / 4.05%',
        otc: '',
        expired: '9/5/2023',
        date: '10/4/2023',
        link: 'https://www.cimb.com.my/content/dam/cimb/personal/documents/promo-tncs/efd-efria-i-campaign-extension/efd-efria-i-tnc-eng.pdf'
    },
    {
        id: '254',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '3.95% / 4.05%',
        otc: '',
        expired: '30/6/2023',
        date: '10/4/2023',
        link: 'https://www.pbebank.com/pdf/Promotions/en/efdfpxntnc.aspx'
    },
    {
        id: '255',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.90% / 3.85%',
        otc: '',
        expired: '30/4/2023',
        date: '10/4/2023',
        link: 'https://www.hlb.com.my/content/dam/hlb/my/images/Promotions/2022/connect-efd/dec/hlb-efd-and-efd-i-tnc-en.pdf'
    },
    {
        id: '256',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '4.10%',
        otc: '',
        expired: '19/4/2023',
        date: '10/4/2023',
        link: ''
    },
    {
        id: '257',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '3.80%',
        expired: '14/4/2023',
        date: '10/4/2023',
        link: ''
    },
    {
        id: '258',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '4.05%',
        otc: '',
        expired: '3/5/2023',
        date: '28/4/2023',
        link: ''
    },
    {
        id: '259',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '4.25%',
        expired: '31/5/2023',
        date: '28/4/2023',
        link: ''
    },
    {
        id: '260',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '3.80% / 4.00%',
        expired: '31/5/2023',
        date: '8/5/2023',
        link: ''
    },
    {
        id: '261',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '4.00% / 4.10%',
        otc: '',
        expired: '31/5/2023',
        date: '9/5/2023',
        link: ''
    },
    {
        id: '262',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.88% / 3.95%',
        otc: '',
        expired: '6/6/2023',
        date: '11/5/2023',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-efria-i.html'
    },
    {
        id: '263',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '3.75% / 3.90%',
        expired: '31/5/2023',
        date: '11/5/2023',
        link: ''
    },
    {
        id: '264',
        name: '9 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '3.95%',
        otc: '',
        expired: '31/5/2023',
        date: '19/5/2023',
        link: ''
    },
    {
        id: '265',
        name: '3 / 6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.99% / 3.99%',
        otc: '',
        expired: '31/5/2023',
        date: '24/5/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-and-efd-i-may-special-promotion-2023.html'
    },
    {
        id: '266',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '3.65% / 3.85%',
        expired: '30/6/2023',
        date: '1/6/2023',
        link: ''
    },
    {
        id: '267',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.83% / 3.90%',
        otc: '',
        expired: '10/7/2023',
        date: '6/6/2023',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-efria-i.html'
    },
    {
        id: '268',
        name: '8 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Bank Rakyat',
        code: 'br',
        efd: '',
        otc: '4.11% / 4.22%',
        expired: '30/9/2023',
        date: '20/6/2023',
        link: 'https://www.bankrakyat.com.my/d/campaigns/campaigns/bank_rakyat_term_deposit_i_account_individual_camp-1932/dwterms/TC-TD_ENG.pdf'
    },
    {
        id: '269',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.99%',
        otc: '',
        expired: '22/6/2023',
        date: '20/6/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-and-efd-i-june-special-promotion-2023.html'
    },
    {
        id: '270',
        name: '3 MONTHS FIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '',
        otc: '4.08%',
        expired: '31/7/2023',
        date: '27/6/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/welcome-proposition-priority-fixed-deposit-promotion2023-pvpb-campaign.html'
    },
    {
        id: '271',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '4.00% / 4.10%',
        otc: '',
        expired: '6/7/2023',
        date: '3/7/2023',
        link: ''
    },
    {
        id: '272',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.85% / 3.80%',
        otc: '',
        expired: '31/7/2023',
        date: '3/7/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/ct-efd-i-promo.html'
    },
    {
        id: '273',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '3.95% / 4.05%',
        otc: '',
        expired: '11/7/2023',
        date: '3/7/2023',
        link: 'https://www.pbebank.com/pdf/Promotions/en/efdfpxntnc.aspx'
    },
    {
        id: '274',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '3.75% / 3.80%',
        expired: '9/7/2023',
        date: '3/7/2023',
        link: ''
    },
    {
        id: '275',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.99%',
        otc: '',
        expired: '7/7/2023',
        date: '5/7/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-and-efd-i-7-7-special-promotion-2023.html'
    },
    {
        id: '276',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.83% / 3.90%',
        otc: '',
        expired: '31/7/2023',
        date: '11/7/2023',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-efria-i.html'
    },
    {
        id: '277',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '3.95% / 4.05%',
        otc: '',
        expired: '31/7/2023',
        date: '13/7/2023',
        link: 'https://www.affinalways.com/storage/campaigns/eFD-eTD-i-Campaign-TnC-ENG.pdf'
    },
    {
        id: '278',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '3.80% / 3.85%',
        otc: '',
        expired: '30/9/2023',
        date: '13/7/2023',
        link: 'https://www.pbebank.com/pdf/Promotions/en/efdfpxntnc.aspx'
    },
    {
        id: '279',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.80% / 3.80%',
        otc: '',
        expired: '13/9/2023',
        date: '28/7/2023',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-efria-i.html'
    },
    {
        id: '280',
        name: '10 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '4.00% - BEST10',
        otc: '',
        expired: '31/8/2023',
        date: '8/8/2023',
        link: ''
    },
    {
        id: '281',
        name: '3 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.95%',
        otc: '',
        expired: '31/8/2023',
        date: '8/8/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-i-august-new-to-eFD-Promotion-2023.html'
    },
    {
        id: '282',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.95%',
        otc: '',
        expired: '31/8/2023',
        date: '8/8/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-i-aug-month-long-promotion-2023.html'
    },
    {
        id: '283',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '3.80% / 3.98%',
        otc: '',
        expired: '31/8/2023',
        date: '14/8/2023',
        link: ''
    },
    {
        id: '284',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '3.85%',
        otc: '',
        expired: '30/9/2023',
        date: '1/9/2023',
        link: ''
    },
    {
        id: '285',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.85% / 3.95%',
        otc: '',
        expired: '10/9/2023',
        date: '4/9/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-i-aug-month-long-promotion-2023.html?icp=hlb-ms-home-popup-img-dp-efd-0823'
    },
    {
        id: '286',
        name: '8 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '4.05% - BEST8',
        otc: '',
        expired: '30/9/2023',
        date: '4/9/2023',
        link: ''
    },
    {
        id: '287',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.75% / 3.90%',
        otc: '',
        expired: '30/9/2023',
        date: '12/9/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-i-month-long-promotion-2023.html'
    },
    {
        id: '288',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.80% / 3.80%',
        otc: '',
        expired: '31/10/2023',
        date: '14/9/2023',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-efria-i.html'
    },
    {
        id: '289',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '3.90%',
        expired: '1/10/2023',
        date: '26/9/2023',
        link: ''
    },
    {
        id: '290',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.85% / 3.85%',
        otc: '',
        expired: '31/10/2023',
        date: '2/10/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-i-month-long-promotion-2023.html'
    },
    {
        id: '291',
        name: '6 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '3.95% - Premier',
        expired: '31/10/2023',
        date: '2/10/2023',
        link: 'https://www.rhbgroup.com/fd/index.html'
    },
    {
        id: '292',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '3.90% - Invikta',
        expired: '31/10/2023',
        date: '2/10/2023',
        link: ''
    },
    {
        id: '293',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '3.90%',
        expired: '8/10/2023',
        date: '4/10/2023',
        link: ''
    },
    {
        id: '294',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '3.90%',
        expired: '15/10/2023',
        date: '10/10/2023',
        link: ''
    },
    {
        id: '295',
        name: '7 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '3.65% / 3.70%',
        otc: '',
        expired: '30/11/2023',
        date: '10/10/2023',
        link: ''
    },
    {
        id: '296',
        name: '6 / 9 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '3.75% / 3.80%',
        expired: '30/11/2023',
        date: '24/10/2023',
        link: 'https://www.ocbc.com.my/personal-banking/deposits/campaign/fixed-deposit-promo'
    },
    {
        id: '297',
        name: '18 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '4.10% - BEST18',
        otc: '',
        expired: '30/11/2023',
        date: '24/10/2023',
        link: ''
    },
    {
        id: '298',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '3.55% / 3.75%',
        expired: '30/11/2023',
        date: '6/11/2023',
        link: ''
    },
    {
        id: '299',
        name: '3 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.53% / 3.75%',
        otc: '',
        expired: '30/11/2023',
        date: '6/11/2023',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-efria-i.html'
    },
    {
        id: '300',
        name: '2 / 7 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.65% / 3.80%',
        otc: '',
        expired: '30/11/2023',
        date: '6/11/2023',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-efria-i.html'
    },
    {
        id: '301',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.90% / 3.75%',
        otc: '',
        expired: '30/11/2023',
        date: '28/11/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-i-month-long-promotion-2023.html'
    },
    {
        id: '302',
        name: '3 / 6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.88% / 3.90%',
        otc: '',
        expired: '8/12/2023',
        date: '4/12/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-i-month-long-promotion-2023.html'
    },
    {
        id: '303',
        name: '7 / 13 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '3.85% / 3.60%',
        otc: '',
        expired: '31/12/2023',
        date: '4/12/2023',
        link: ''
    },
    {
        id: '304',
        name: '3 / 12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '3.65% / 3.90%',
        expired: '31/12/2023',
        date: '7/12/2023',
        link: ''
    },
    {
        id: '305',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '3.95% - Invikta',
        expired: '31/12/2023',
        date: '7/12/2023',
        link: ''
    },
    {
        id: '306',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.90% / 3.85%',
        otc: '',
        expired: '21/1/2024',
        date: '11/12/2023',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-i-month-long-promotion-2023.html'
    },
    {
        id: '307',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '3.80% / 3.85%',
        otc: '',
        expired: '14/3/2024',
        date: '4/1/2024',
        link: 'https://www.pbebank.com/media/dhvckdif/efdfpxntnc-en.pdf'
    },
    {
        id: '308',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '3.55% / 3.75%',
        expired: '31/1/2024',
        date: '4/1/2024',
        link: ''
    },
    {
        id: '309',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '3.98% - BEST6',
        otc: '',
        expired: '18/2/2024',
        date: '8/1/2024',
        link: ''
    },
    {
        id: '310',
        name: '2 / 7 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.65% / 3.80%',
        otc: '',
        expired: '31/1/2024',
        date: '16/1/2024',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/cimb-efd-efria-i-october-2023-campaign.html'
    },
    {
        id: '311',
        name: '6 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '4.08%',
        expired: '3/2/2024',
        date: '16/1/2024',
        link: ''
    },
    {
        id: '312',
        name: '3 / 7 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.65% / 3.80%',
        otc: '',
        expired: '15/3/2024',
        date: '19/2/2024',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/cimb-efd-i-cny-campaign.html'
    },
    {
        id: '313',
        name: '18 / 24 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.38% / 3.48%',
        otc: '',
        expired: '29/2/2024',
        date: '19/2/2024',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-i-18-and-24-months-cny-promotion-2024.html'
    },
    {
        id: '314',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '4.05% - Invikta',
        expired: '31/3/2024',
        date: '7/3/2024',
        link: ''
    },
    {
        id: '315',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '3.85% - Private',
        expired: '31/3/2024',
        date: '7/3/2024',
        link: ''
    },
    {
        id: '316',
        name: '9 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '3.70% / 3.75%',
        otc: '',
        expired: '31/3/2024',
        date: '15/3/2024',
        link: 'https://www.pbebank.com/media/dhvckdif/efdfpxntnc-en.pdf'
    },
    {
        id: '317',
        name: '6 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.75%',
        otc: '',
        expired: '31/3/2024',
        date: '18/3/2024',
        link: ''
    },
    {
        id: '318',
        name: '3 / 7 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.60% / 3.75%',
        otc: '',
        expired: '1/4/2024',
        date: '20/3/2024',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-i-2024-campaign.html'
    },
    {
        id: '319',
        name: '10 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'HongLeong Bank',
        code: 'hlb',
        efd: '3.70% / 3.65%',
        otc: '',
        expired: '30/4/2024',
        date: '1/4/2024',
        link: 'https://www.hlb.com.my/en/personal-banking/promotions/dp-efd-i-month-long-promotion-2024.html'
    },
    {
        id: '320',
        name: '9 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '3.65% / 3.70%',
        expired: '31/5/2024',
        date: '1/4/2024',
        link: 'https://www.pbebank.com/media/derjydpq/pb-special-fd-tc-eng-_1-apr-2024.pdf'
    },
    {
        id: '321',
        name: '10 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '3.80% - INVIKTA',
        expired: '30/4/2024',
        date: '15/4/2024',
        link: ''
    },
    {
        id: '322',
        name: '12 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '3.80% - BEST12',
        otc: '',
        expired: '30/4/2024',
        date: '15/4/2024',
        link: ''
    },
    {
        id: '323',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '3.75% - Private',
        expired: '30/4/2024',
        date: '15/4/2024',
        link: ''
    },
    {
        id: '324',
        name: '3 / 7 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.55% / 3.70%',
        otc: '',
        expired: '3/5/2024',
        date: '15/4/2024',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-i-2024-campaign.html'
    },
    {
        id: '325',
        name: '6 / 9 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '3.65% / 3.70%',
        expired: '15/5/2024',
        date: '2/5/2024',
        link: ''
    },
    {
        id: '326',
        name: '3 / 7 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.55% / 3.70%',
        otc: '',
        expired: '18/5/2024',
        date: '10/5/2024',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-i-2024-campaign.html'
    },
    {
        id: '327',
        name: '6 / 12 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.65% / 3.70%',
        otc: '',
        expired: '18/5/2024',
        date: '10/5/2024',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-i-2024-campaign.html'
    },
    {
        id: '328',
        name: '3 / 7 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.55% / 3.70%',
        otc: '',
        expired: '18/6/2024',
        date: '20/5/2024',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-i-may-2024-campaign.html'
    },
    {
        id: '329',
        name: '6 / 9 MONTHS FIXED DEPOSIT',
        bank: 'RHB Bank',
        code: 'rhb',
        efd: '',
        otc: '3.65% / 3.70%',
        expired: '31/5/2024',
        date: '20/5/2024',
        link: ''
    },
    {
        id: '330',
        name: '6 / 12 MONTHS FIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '',
        otc: '3.70% / 3.75%',
        expired: '31/5/2024',
        date: '20/5/2024',
        link: ''
    },
    {
        id: '331',
        name: '6 / 13 MONTHS FIXED DEPOSIT',
        bank: 'OCBC Bank',
        code: 'ocbc',
        efd: '',
        otc: '3.50% / 3.60%',
        expired: '31/5/2024',
        date: '28/5/2024',
        link: ''
    },
    {
        id: '332',
        name: '3 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '3.60% - BEST3',
        otc: '',
        expired: '31/5/2024',
        date: '28/5/2024',
        link: ''
    },
    {
        id: '333',
        name: '9 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Public Bank',
        code: 'pb',
        efd: '',
        otc: '3.50% / 3.55%',
        expired: '30/6/2024',
        date: '6/6/2024',
        link: 'https://www.pbebank.com/media/v2hdvr5a/tc_pb-specialfd-en310524.pdf'
    },
    {
        id: '334',
        name: '8 / 12 MONTHS FIXED DEPOSIT',
        bank: 'Bank Rakyat',
        code: 'br',
        efd: '',
        otc: '3.70% / 3.80%',
        expired: '30/9/2024',
        date: '14/6/2024',
        link: ''
    },
    {
        id: '335',
        name: '3 / 7 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.55% / 3.70%',
        otc: '',
        expired: '15/7/2024',
        date: '20/6/2024',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-i-may-2024-campaign.html'
    },
    {
        id: '336',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '3.65%',
        expired: '30/6/2024',
        date: '20/6/2024',
        link: ''
    },
    {
        id: '337',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '3.80% - INVIKTA',
        expired: '30/6/2024',
        date: '20/6/2024',
        link: ''
    },
    {
        id: '338',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'Affin Bank',
        code: 'affin',
        efd: '',
        otc: '3.70% - INVIKTA',
        expired: '15/7/2024',
        date: '9/7/2024',
        link: ''
    },
    {
        id: '339',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '3.65% - PRIVATE',
        expired: '31/7/2024',
        date: '9/7/2024',
        link: ''
    },
    {
        id: '340',
        name: '18 MONTHS eFIXED DEPOSIT',
        bank: 'AmBank',
        code: 'ambank',
        efd: '3.80% - BEST18',
        otc: '',
        expired: '31/7/2024',
        date: '19/7/2024',
        link: ''
    },
    {
        id: '341',
        name: '3 / 7 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.55% / 3.70%',
        otc: '',
        expired: '31/7/2024',
        date: '19/7/2024',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-i-may-2024-campaign.html'
    },
    {
        id: '342',
        name: '3 / 6 MONTHS eFIXED DEPOSIT',
        bank: 'CIMB Bank',
        code: 'cimb',
        efd: '3.50% / 3.60%',
        otc: '',
        expired: '29/8/2024',
        date: '5/8/2024',
        link: 'https://www.cimb.com.my/en/personal/promotions/latest-promotions/efd-i-aug-2024-campaign.html'
    },
    {
        id: '343',
        name: '12 MONTHS FIXED DEPOSIT',
        bank: 'UOB Bank',
        code: 'uob',
        efd: '',
        otc: '3.65% - PRIVATE',
        expired: '31/8/2024',
        date: '21/8/2024',
        link: ''
    },
]
