import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import data from '../static/data/20200707/data';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: 'white',
      backgroundColor: '#3f51b5',
      position: 'relative',
      bottom: 0,
      width: '100%'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center'
    },
    nolist: {
        padding: 0,
        listStyleType: 'none',
        '& li': {
            padding: 5
        },
        '& a': {
            textDecoration: 'none',
            color: '#FFFFFF'
        }
    },
  }),
);

const Footer = () => {
    const classes = useStyles();
    const [lastUpdatedDate, setLastUpdatedDate] = useState('');

    useEffect(() => {
        const newData = [...data].sort((a, b) =>  b.id - a.id);
        const updatedDate = newData[0].date;
        setLastUpdatedDate(updatedDate);
    }, []);

    return(
        <div className={classes.root}>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className={classes.paper}>
                        <h4>SOCIAL MEDIA</h4>
                        <ul className={classes.nolist}>
                            <li>Facebook</li>
                            <li>Twitter</li>
                            <li>Instagram</li>
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className={classes.paper}>
                        <h4>PARTNERSHIP</h4>
                        <ul className={classes.nolist}>
                            <li>
                                <Link to="/about">About Us</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact Us</Link>
                            </li>
                            {/* <li>Write Us</li> */}
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className={classes.paper}>
                        <h4>OVERNIGHT POLICY RATE</h4>
                        <ul className={classes.nolist}>
                            <li>
                                At its meeting today, the Monetary Policy Committee (MPC) of Bank Negara Malaysia decided to maintain the Overnight Policy Rate (OPR) at 3.00 percent. <br/>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.bnm.gov.my/-/monetary-policy-statement-09052024">
                                    [9 May 2024]
                                </a>
                            </li>
                            {/* <li>BBB</li>
                            <li>CCC</li> */}
                        </ul>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.paper}>
                        &copy;{new Date().getFullYear()} MYFDRATES.COM | All Right Reserved | Last Updated: {lastUpdatedDate}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Footer;
